<template>
    <div class="wave-container">
      <svg viewBox="0 0 1600 200" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="#4f53f3"
          opacity="1"
          d="M0,97C534,59,1068,47,1602,85,C1600,100,1600,100,1600,100C1600,100,1600,100,1600,100C1600,100,1600,100,1600,100C1600,100,1600,100,1600,100L1600,100C1066,100,532,100,-2,100,C0,100,0,100,0,100C0,100,0,100,0,100C0,100,0,100,0,100C0,100,0,100,0,100L1398,100L0,100Z"
        />
        <path
          fill="#3a339b"
          opacity="1"
          d="M0,17C534,102,1068,147,1602,119,C1600,100,1600,100,1600,100C1600,100,1600,100,1600,100C1600,100,1600,100,1600,100C1600,100,1600,100,1600,100C1600,100,1600,100,1600,100L1600,100C1066,100,532,100,-2,100,C0,100,0,100,0,100C0,100,0,100,0,100C0,100,0,100,0,100C0,100,0,100,0,100C0,100,0,100,0,100L1398,100L0,100Z"
        />
        <path
          fill="#333aab"
          opacity="1"
          d="M0,134C534,127,1068,132,1602,35,C1600,100,1600,100,1600,100C1600,100,1600,100,1600,100C1600,100,1600,100,1600,100C1600,100,1600,100,1600,100C1600,100,1600,100,1600,100L1600,100C1066,100,532,100,-2,100,C0,100,0,100,0,100C0,100,0,100,0,100C0,100,0,100,0,100C0,100,0,100,0,100C0,100,0,100,0,100L1398,100L0,100Z"
        />
      </svg>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Wave3Component',
  };
  </script>
  
  <style scoped>
  .wave-container {
    margin: 0; /* Réduire la marge autour du conteneur */
    padding: 0; /* Réduire le remplissage si nécessaire */
  }
  </style>
  