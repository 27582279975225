<template>
  <div>
    <!-- Ajout du composant Wave3 en haut -->
    <Wave3Component class="full-width-wave" />

    <section class="collaboration">
      <h2>Collaboration</h2>
      <p class="description">Une application multi-usages :</p>

      <!-- Section 1 -->
      <section class="additional-content">
        <div class="content-wrapper">
          <div class="image-container reverse-animation">
            <img src="@/assets/collab/collab8.jpg" alt="Interface unifiée" class="overflow-image reduced-size" />
          </div>
          <div class="text-content">
            <h2>Interface unifiée</h2>
            <p>Wildix Collaboration offre une plateforme tout-en-un où tous les outils de communication sont centralisés. Que ce soit pour discuter par chat, passer des appels ou organiser des visioconférences, tout se fait à partir d'une interface unique, accessible depuis n'importe quel navigateur web ou via une application dédiée. Cette interface simplifie les interactions et améliore l'efficacité, car il n'est pas nécessaire de basculer entre plusieurs outils ou plateformes.</p>
          </div>
        </div>
      </section>

      <!-- Section 2 -->
      <section class="additional-content reverse">
        <div class="content-wrapper">
          <div class="text-content">
            <h2>Appels et gestion des contacts</h2>
            <p>Les utilisateurs peuvent gérer facilement leurs communications téléphoniques grâce à l'intégration VoIP (Voice over IP). Ils peuvent passer et recevoir des appels directement depuis l'interface de Wildix Collaboration. En plus de la gestion des appels, les utilisateurs ont accès à un annuaire de contacts centralisé, peuvent consulter l'historique des appels et retrouver rapidement les informations de leurs collègues ou clients. La synchronisation avec les contacts d'autres outils ou CRM est également possible, facilitant la gestion des relations professionnelles.</p>
          </div>
          <div class="image-container animated-image">
            <img src="@/assets/collab/collab2.jpg" alt="Appels et gestion des contacts" class="overflow-image reduced-size" />
          </div>
        </div>
      </section>

      <!-- Ajout de la Wave1 après la section 2 -->
      <div class="wave-spacing">
        <Wave3Component class="full-width-wave" />
      </div>

      <!-- Section 3 -->
      <section class="additional-content">
        <div class="content-wrapper">
          <div class="image-container reverse-animation">
            <img src="@/assets/collab/collab3.jpg" alt="Chat en temps réel" class="overflow-image" />
          </div>
          <div class="text-content">
            <h2>Chat en temps réel</h2>
            <p>La messagerie instantanée est une fonctionnalité clé de Wildix Collaboration. Elle permet d'envoyer des messages textes en temps réel à un ou plusieurs collaborateurs, qu’ils soient à proximité ou à distance. Les discussions de groupe peuvent être créées pour favoriser la communication en équipe. Des notifications intégrées garantissent que les utilisateurs restent informés des nouveaux messages, et peuvent répondre rapidement, améliorant ainsi la réactivité des équipes.</p>
          </div>
        </div>
      </section>

      <!-- Section 4 -->
      <section class="additional-content reverse">
        <div class="content-wrapper">
          <div class="text-content">
            <h2>Partage de fichiers et de documents</h2>
            <p>Le partage de fichiers est une fonctionnalité essentielle dans un environnement de travail collaboratif. Avec Wildix Collaboration, les utilisateurs peuvent facilement envoyer des fichiers et documents dans les chats ou lors de visioconférences. Cela permet un partage instantané des informations et des documents importants, simplifiant ainsi le travail d'équipe et la collaboration sur des projets en temps réel, sans avoir à passer par des emails ou des outils externes.</p>
          </div>
          <div class="image-container animated-image">
            <img src="@/assets/collab/collab4.jpg" alt="Partage de fichiers" class="overflow-image reduced-size" />
          </div>
        </div>
      </section>

      <!-- Ajout de la Wave2 après la section 4 -->
      <div class="wave-spacing">
        <Wave2Component class="full-width-wave" />
      </div>

      <!-- Section 5 -->
      <section class="additional-content">
        <div class="content-wrapper">
          <div class="image-container reverse-animation">
            <img src="@/assets/collab/collab5.jpg" alt="Vidéo et conférences audio" class="overflow-image reduced-size" />
          </div>
          <div class="text-content">
            <h2>Vidéo et conférences audio</h2>
            <p>Wildix Collaboration inclut des outils de visioconférence et de conférence audio, idéals pour organiser des réunions à distance. Les utilisateurs peuvent facilement démarrer des appels vidéo en un clic, partager leur écran pour présenter des documents ou des projets, et inviter plusieurs participants à collaborer. Ces fonctionnalités sont conçues pour favoriser une interaction fluide, même pour les équipes dispersées géographiquement, améliorant ainsi la productivité des réunions.</p>
          </div>
        </div>
      </section>

      <!-- Section 6 -->
      <section class="additional-content reverse">
        <div class="content-wrapper">
          <div class="text-content">
            <h2>Présence et disponibilité</h2>
            <p>Un des atouts majeurs de Wildix Collaboration est l'affichage en temps réel de la disponibilité des collaborateurs. Les utilisateurs peuvent voir si un collègue est disponible, en réunion, absent, ou occupé. Cela permet de planifier la communication plus efficacement et d'éviter d'interrompre les collaborateurs pendant des moments inopportuns. La visibilité sur le statut de chaque personne aide également à réduire les délais d'attente pour des réponses ou des prises de décision.</p>
          </div>
          <div class="image-container animated-image">
            <img src="@/assets/collab/collab6.jpg" alt="Présence et disponibilité" class="overflow-image reduced-size" />
          </div>
        </div>
      </section>

      <!-- Ajout de la Wave3 après la section 6 -->
      <div class="wave-spacing">
        <Wave3Component class="full-width-wave" />
      </div>

      <!-- Section 7 -->
      <section class="additional-content">
        <div class="content-wrapper">
          <div class="image-container reverse-animation">
            <img src="@/assets/collab/collab7.jpg" alt="Intégration et API" class="overflow-image" />
          </div>
          <div class="text-content">
            <h2>Intégration et API</h2>
            <p>Wildix Collaboration est conçu pour s'intégrer facilement avec d'autres logiciels d'entreprise, comme les CRM, ERP, et autres outils de gestion d'entreprise. Grâce à des API (interfaces de programmation), les entreprises peuvent connecter Wildix à leurs systèmes existants, automatiser les processus et améliorer la gestion des interactions clients. Cette intégration permet une meilleure synchronisation des données et améliore l'efficacité globale des opérations.</p>
            <router-link to="/integration" class="integration-button">Vers nos intégrations</router-link>
          </div>
        </div>
      </section>

      <!-- Section 8 -->
      <section class="additional-content reverse">
        <div class="content-wrapper">
          <div class="text-content">
            <h2>Sécurité et cloud</h2>
            <p>Wildix Collaboration est une solution entièrement hébergée dans le cloud, ce qui signifie que les entreprises bénéficient d'une grande flexibilité et d'une haute disponibilité des services sans avoir à gérer une infrastructure locale. La plateforme respecte les normes de sécurité les plus strictes pour garantir la protection des données et des communications. Des mécanismes avancés de chiffrement sont utilisés pour assurer la confidentialité des informations échangées, tout en offrant une performance stable et une résilience face aux pannes.</p>
          </div>
          <div class="image-container animated-image">
            <img src="@/assets/collab/collab1.jpg" alt="Sécurité et cloud" class="overflow-image" />
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import Wave3Component from '@/components/utilities/wave3.vue'; // Assurez-vous que le chemin est correct
import Wave2Component from '@/components/utilities/wave2.vue';

export default {
  name: 'SolutionCollaboration',
  components: {
    Wave3Component, // Enregistrement du composant
    Wave2Component,
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

body {
  font-family: 'Poppins', sans-serif;
}

.collaboration {
  padding: 4rem 2rem;
  padding-top: 8rem;
}

.collaboration h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-align: center;
  color: #333;
}

.collaboration .description {
  text-align: center;
  margin-bottom: 2.5rem;
  font-size: 1.75rem;
  color: #007BFF;
}

.additional-content {
  padding: 4rem 2rem;
  position: relative;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 4rem;
  transition: transform 0.3s ease;
}

.additional-content:hover {
  transform: translateY(-10px);
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin-top: 1.5rem;
}

.text-content {
  width: 55%;
  text-align: left;
}

.text-content h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #007BFF;
}

.text-content p {
  font-size: 1.125rem;
  line-height: 1.8;
  color: #555;
}

.image-container {
  width: 45%;
  display: flex;
  justify-content: center;
  overflow: visible;
}

.image-container.animated-image {
  transform: perspective(800px) rotateY(-5deg);
}

.image-container.reverse-animation {
  transform: perspective(800px) rotateY(5deg);
}

.image-container img {
  max-width: 100%;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
}

.image-container img.reduced-size {
  max-width: 70%;
}

.overflow-image {
  position: relative;
  width: 100%;
  left: 0;
}

.reverse {
  flex-direction: row-reverse;
}

.integration-button {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  background-color: #007BFF;
  color: white;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.integration-button:hover {
  background-color: #0056b3;
  transform: translateY(-3px);
}

.wave-spacing {
  margin: 2rem 0;
}

.full-width-wave {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

@media (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
    gap: 1rem;
  }

  .text-content, .image-container {
    width: 100%;
  }

  .text-content {
    text-align: center;
  }
}
</style>